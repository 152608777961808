export const useSessionStorage = () => {
  const setSessionStorage = (key, value) => {
    const temp = JSON.stringify(value);
    sessionStorage.setItem(key, temp);
  };

  const getSessionStorage = (key) => {
    const temp = sessionStorage.getItem(key);
    if (temp !== null) {
      return JSON.parse(temp);
    }

    return;
  };

  const removeSessionStorage = (key) => {
    sessionStorage.removeItem(key);
  }

  return { setSessionStorage, getSessionStorage, removeSessionStorage };
}