import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { analytics, gtmEvent } from "@/utils/gtm";

export default function Analytics() {
  const { locale } = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    analytics(window, document, "script", "dataLayer", "GTM-MPMKJKP");
  }, []);

  useEffect(() => {
    gtmEvent("page_view", {
      page: {
        url: pathname,
      },
    });
  }, [pathname]);

  useEffect(() => {
    gtmEvent("switch_lang", {
      locale: locale,
    });
  }, [locale]);

  return null;
}
