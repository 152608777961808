import React, { useCallback, useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import * as styles from "./Layout.module.scss";
import { useSessionStorage } from "@/hooks/useSessionStorage";
import InfoBar from "../Story/InfoBar";
import Modal from "../Core/Modal";
import Text from "../Core/Text";
import { slugify } from "@/utils/format";
import Button from "../Core/Button";
import Icon from "@/utils/Icon";
import Image from "next/image";
import { StoryblokComponent } from "@storyblok/react";
import Paragraph, { storyblokContentRenderOptions } from "../Story/Paragraph";
import { render } from "storyblok-rich-text-react-renderer";
import Analytics from "../Analytics";

export default function Layout(props) {
  const {
    children,
    story,
    settings,
    locales,
    locale,
    defaultLocale,
    className,
    navigationMode,
  } = props;
  const { setSessionStorage } = useSessionStorage();
  const [modalOpen, setModalOpen] = useState(settings?.content?.popins?.map((popin) => false) || []);
  const [popin, setPopin] = useState(null);

  const displayPopin = useCallback((popins) => {
    for (let i = 0; i < popins.length; i++) {
      const popin = popins[i];

      // Check if the popin is not active but has a cookie
      if (popin && !popin?.active) {
        const cookieName = `popin_${popin?._uid}`;
        const cookieValue = getCookie(cookieName);

        // If the cookie exists, delete it
        if (cookieValue) {
          setCookie(cookieName, '', -1);
        }

        continue;
      } else if (popin?.active && popin?.appearOn?.includes(story?.uuid)) {
        // Check if a cookie exists for this popin
        const cookieName = `popin_${popin?._uid}`;
        const cookieValue = getCookie(cookieName);

        const hasCookie = cookieValue ? true : false;
        let isClosed = cookieValue?.split(':') ? cookieValue?.split(':')[1] : false;

        if (hasCookie) {
          // Check the closed status of the cookie
          const [expirationTime, closed] = cookieValue.split(':');
          if (closed === 'true') {
            continue;
          }
        }

        // If the cookie doesn't exist or has expired, display the popin
        if (!cookieValue || Date.now() > parseInt(cookieValue) || (hasCookie && isClosed === 'false')) {
          // Set the cookie value with the expirationTime and the closed status
          const expireAfter = popin?.expireAfter ? parseInt(popin?.expireAfter) : 604800;
          const expirationTime = Date.now() + (popin?.expireAfter * 1000);

          // Check if the popin activeUntil is set, and if it is, check if the current date is before the activeUntil date
          if (popin?.activeUntil) {
            const activeUntil = new Date(popin?.activeUntil);
            console.log('activeUntil - has limit date(still valid ?)', activeUntil, !(Date.now() > activeUntil))
            if (Date.now() > activeUntil) {
              continue;
            }
          }

          console.log('Add new cookie', cookieName, `${expirationTime}:false`, expireAfter)

          // Set the cookie
          const newCookieValue = `${expirationTime}:false`;
          setCookie(cookieName, newCookieValue, expireAfter);

          //   // Update the modalOpen state variable
          //   let newModalOpenState = [...modalOpen];
          //   newModalOpenState[i] = true;
          //   setModalOpen(newModalOpenState);

          return popin;
        } else if (cookieValue) {
          const [expirationTime, closed] = cookieValue.split(':');
          if (closed === 'true') {
            continue;
          }
        }
      }
    }

    // No popin should be displayed
    return null;
  }, [])

  // Function to get a cookie value by name
  const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      // Also return the cookie expiration time
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  // Function to set a cookie with an expiration time
  const setCookie = (name, value, ttl) => {
    const expirationDate = new Date(Date.now() + ttl);
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  }

  useEffect(() => {
    if (settings?.content?.popins?.length > 0) {
      // const modalOpenStatus = settings.content.popins.map((popin) => popin.active);
      // setModalOpen(modalOpenStatus);

      // console.log('Set popin', displayPopin(settings?.content?.popins))
      setTimeout(() => {
        const popinToDisplay = displayPopin(settings?.content?.popins);
        setPopin(popinToDisplay);
      }, 2000);
    }
  }, [settings, displayPopin]);

  useEffect(() => {
    const k = ["g", "r", "i"];
    const kHide = "Escape";
    let n = 0;

    if (typeof window !== "undefined" && window.document) {
      window.document.addEventListener("keydown", function (e) {
        const body = window.document.querySelector("body");
        if (e.key === k[n++]) {
          if (n === k.length) {
            if (body) {
              body.classList.add("grid-visible");
            }
            n = 0;
            return false;
          }
        } else {
          n = 0;
        }
        if (e.key === kHide) {
          if (body) {
            body.classList.remove('grid-visible');
          }
          return false;
        }
      });

      const storyblokSeasonMode =
        settings && settings.content && settings.content.saison;
      let seasonMode = storyblokSeasonMode;
      const body = window.document.querySelector('body');

      let lsSeasonMode = localStorage.getItem('seasonMode');

      if (lsSeasonMode) {
        if (storyblokSeasonMode) {
          if (lsSeasonMode !== storyblokSeasonMode) {
            localStorage.setItem('seasonMode', storyblokSeasonMode);
            seasonMode = storyblokSeasonMode;
          }
        } else {
          seasonMode = lsSeasonMode;
        }
      } else if (storyblokSeasonMode) {
        localStorage.setItem('seasonMode', storyblokSeasonMode);
        seasonMode = storyblokSeasonMode;
      }

      if (body) body.classList.add(seasonMode);
    }
  }, [settings, setSessionStorage]);

  const handleCloseModal = () => {
    // Edit the current popin cookie with the value closed: true
    const cookieName = `popin_${popin?._uid}`;
    const cookieValue = getCookie(cookieName);

    if (cookieValue) {
      // Parse the cookie value containing the expiration time and the closed status
      const [expirationTime, closed] = cookieValue.split(':');
      const newCookieValue = `${expirationTime}:true`;
      setCookie(cookieName, newCookieValue, expirationTime);
    }

    setModalOpen([false]);
    setPopin(null);
  }

  const handleClickCtaPopin = () => {
    handleCloseModal();
  }

  const hasInfoBar =
    navigationMode === 'simple' || navigationMode === 'map' &&
    settings?.content?.infobar &&
    settings?.content?.infobar[0];
  return (
    <div id="page" className={`${hasInfoBar ? styles.pageWithInfobar : ""} overflow-x-hidden`}>
      {hasInfoBar && (
        <InfoBar blok={settings?.content?.infobar[0]} fixed />
      )}
      <Navigation
        blok={
          settings?.content?.header
            ? settings?.content?.header[0]
            : null
        }
        locales={locales}
        locale={locale}
        defaultLocale={defaultLocale}
        mode={navigationMode}
      />
      <main className={className}>{children}</main>
      <Modal isOpen={popin} onClose={handleCloseModal} large>
        <div className={`${styles.layoutPopin} relative h-full p-6 lg:px-8 lg:py-6 lg:flex items-center`}>
          <button
            onClick={handleCloseModal}
            className="absolute right-8 top-6 border border-black/20 rounded-full p-3 hover:bg-black/5 hover:border-black/40 transition-colors"
          >
            <Icon icon="icon-cross-16" size={24} />
          </button>

          {popin?.image?.filename && <div
            className={`${styles.layoutPopinImage} relative lg:w-5/12 overflow-hidden rounded-xl mb-5 lg:mb-0 lg:mr-8 bg-gray-100`}
          >
            <Image
              src={popin.image?.filename}
              alt={popin.image?.alt || "missing alt"}
              fill
              sizes="(max-width: 768px) 80vw, (max-width: 1024px) 40vw, 33vw"
              className="object-cover"
            />
          </div>}

          <div className={`flex-1 pr-6 lg:pr-12 ${!popin?.image?.filename ? 'text-center lg:pl-12' : ''}`}>
            <Text as="h4">{popin?.title}</Text>
            {popin?.description && popin?.description?.content && (
              <div className="mt-6">
                {render(popin?.description, storyblokContentRenderOptions)}
              </div>
            )}
            {popin?.cta[0] && popin?.cta[0]?.link && (
              <Button
                link={popin.cta[0].link}
                // variant="phantom"
                // size="Small"
                onClick={handleClickCtaPopin}
                className="mt-6"
              >
                {popin.cta[0].label}
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <Footer
        blok={
          settings && settings.content && settings.content.footer
            ? settings.content.footer[0]
            : null
        }
        mode={navigationMode}
      />
      <Analytics />
    </div>
  );
}
