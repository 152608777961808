import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import Image from "next/image";
import * as styles from "./Footer.module.scss";
import Text from "../Core/Text";
import PartnersLabelsBlock from "../Story/PartnersLabelsBlock";
import LangSwitcher from "../LangSwitcher";
import Script from "next/script";

const Footer = ({ blok, mode }) => {
  const sbEditableProps = blok ? storyblokEditable(blok) : {};

  const footerPartners = blok && blok.partners && [...blok.partners];
  const footerMenu = blok && blok.menu && [...blok.menu];
  // let newsletterAndApps = [];
  // if (footerMenu) {
  //   // Remove the item with the component key value "footer_newsletter" and push it to the newsletterAndApps array
  //   const newsletterIndex = footerMenu.findIndex(
  //     (item) =>
  //       item.component === "footer_newsletter" && newsletterAndApps.push(item)
  //   );
  //   footerMenu.splice(newsletterIndex, 1);

  //   // // Remove the last paragraph item and push it to the newsletterAndApps array
  //   newsletterAndApps.push(footerMenu.pop());

  //   // // Remove items at index newsletterIndex and appsIndex
  //   // footerMenu.splice(appsIndex, 1);
  // }

  const currentYear = new Date().getFullYear();

  return (
    <>
      {footerPartners && mode !== 'map' && <PartnersLabelsBlock blok={footerPartners} />}

      <footer
        className="relative bg-footerBg text-white lg:bottom-0 block w-full z-10 py-14 lg:pt-20"
        aria-labelledby="footer-heading"
        {...sbEditableProps}
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="container">
          <div className="flex flex-col lg:grid grid-cols-12 gap-x-3">
            <div className="text-center lg:text-left lg:col-span-3">
              <Link href="/" className="inline-block">
                <span className="sr-only">Courchevel</span>
                {blok && blok.logo ? (
                  <Image
                    src={blok.logo.filename}
                    alt={blok.logo.alt}
                    width={170}
                    height={48}
                    className={styles.footerLogo}
                  />
                ) : (
                  <div className="inline-block w-12 h-12 rounded-full bg-grey-500" />
                )}
              </Link>
            </div>

            <div className="flex flex-col lg:col-span-9 lg:grid lg:grid-cols-9 lg:gap-y-16 gap-x-3 mt-8 lg:mt-0">
              {footerMenu?.map((nestedBlok, index) => {
                if (nestedBlok.component) {
                  return <StoryblokComponent
                    className={`${nestedBlok.component !== "link_list" ? "mt-12 lg:mt-0" : ""
                      } ${styles.footerGroup} relative pr-4 lg:col-span-3 lg:pr-8`}
                    blok={nestedBlok}
                    key={nestedBlok._uid}
                    index={index}
                    inFooter
                  />
                }
              }
              )}
              {/* <div className="lg:col-start-7 lg:col-end-10 lg:pr-8">
              {newsletterAndApps.map((nestedBlok, index) => (
                <StoryblokComponent
                  className={`mt-12 ${index === 0 ? "lg:mt-0" : ""} ${
                    styles.footerGroup
                  } relative pr-4`}
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                  index={index}
                />
              ))}
            </div> */}
            </div>
          </div>
        </div>
        <div className="container pt-10 flex items-end md:items-center lg:grid grid-cols-12 lg:pt-16 gap-x-3">
          <ul className="flex flex-col lg:flex-row lg:gap-x-6 md:order-1 lg:col-span-6 opacity-50">
            {blok?.cms?.map((cmsItem) => (
              <li key={cmsItem._uid} className="mb-2">
                <StoryblokComponent blok={cmsItem} textStyle="ui1" />
              </li>
            ))}
          </ul>
          <div className="mt-8 md:mt-0 md:order-2 lg:col-span-3">
            <Text as="body3" tag="div" className=" text-sm font-light text-gray-500">
              Copyright © 2017-{currentYear} - Site by <a href="https://wiz.fr/" rel="noopener noreferrer" target="_blank" className="hover:underline">WIZ</a>
            </Text>
          </div>
          <div className="lg:col-span-3">
            <LangSwitcher />
          </div>
        </div>

        <Script id="footer-newsletter">
          {`window.REQUIRED_CODE_ERROR_MESSAGE = 'Veuillez choisir un code pays';
          window.LOCALE = 'fr';
          window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "Les informations que vous avez fournies ne sont pas valides. Veuillez vérifier le format du champ et réessayer.";

          window.REQUIRED_ERROR_MESSAGE = "Vous devez renseigner ce champ. ";

          window.GENERIC_INVALID_MESSAGE = "Les informations que vous avez fournies ne sont pas valides. Veuillez vérifier le format du champ et réessayer.";

          window.translation = {
            common: {
              selectedList: '{quantity} liste sélectionnée',
              selectedLists: '{quantity} listes sélectionnées'
            }
          };

          var AUTOHIDE = Boolean(0);`}
        </Script>
        <Script defer src="https://sibforms.com/forms/end-form/build/main.js" />
      </footer>
    </>
  );
};

export default Footer;
