import Text from "../../Core/Text";
import * as styles from './PartnersLabelsBlock.module.scss';

import LabelQualite from "@/public/logos/qualite-tourisme-logo.svg";
import LabelFamille from "@/public/logos/famille-plus-logo.svg";
import LabelStationVerte from "@/public/logos/Logo_Station_Verte.svg";
import Label3vallees from "@/public/logos/Logo_Les_3_Vallees.svg";
import Link from "next/link";
import Image from "next/image";

const PartnersLabelsBlock = ({ blok }) => {
  const partners = blok[0]
  const labels = blok[1]

  return (
    <div
      className={`relative s-PartnersLabelsBlock bg-white border-t border-filet w-full z-10`}
    >
      <div className="container flex flex-col lg:flex-row lg:gap-x-3">
        <div className="w-full py-8 lg:py-16 lg:w-5/12 border-b lg:border-b-0 lg:border-r border-filet">
          <Text as="h6" className="lg:max-w-lg">{partners.title}</Text>
          <div className={`flex flex-wrap mt-5 gap-3 lg:mt-8`}>
            {partners.images && partners.images.map((imageItem) => {
              const LinkTag = imageItem.link && imageItem.link.target === '_blank' ? "a" : Link
              return <LinkTag key={imageItem._uid} href={imageItem.link && imageItem.link.cached_url} target={imageItem.link && imageItem.link.target} aria-label={imageItem.link && imageItem.link.cached_url} className={`${styles.partnerItemLogo} flex items-center h-20`
              }>
                {imageItem.image && <Image src={imageItem.image.filename} alt={imageItem.image.alt} width={80} height={120} />}
              </LinkTag>
            })}
          </div>
        </div>
        <div className="w-full py-8 lg:py-16 lg:w-7/12 lg:pl-16">
          <Text as="h6" className="lg:max-w-lg">{labels.title}</Text>
          <div className={`flex flex-wrap mt-5 gap-3 lg:mt-8`}>
            {labels.images && labels.images.map((imageItem) => {
              const LinkTag = imageItem.link && imageItem.link.target === '_blank' ? "a" : Link
              return <LinkTag key={imageItem._uid} href={imageItem.link && imageItem.link.cached_url} target={imageItem.link && imageItem.link.target} aria-label={imageItem.link && imageItem.link.cached_url} className={`${styles.partnerItemLogo} flex items-center h-20`}>
                {imageItem.image && <Image src={imageItem.image.filename} alt={imageItem.image.alt} width={80} height={120} />}
              </LinkTag>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersLabelsBlock;
